import { NextPageContext } from 'next'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import sha256 from 'utils/sha256'
import apiUrl from 'utils/apiUrl'

import parseSetCookie, { splitCookiesString } from './parseSetCookie'

// setLogVerbosity('log')

function createCookieLink(context: NextPageContext | null) {
  return setContext((_request, { headers }) => {
    const ssrCookie = context?.req?.headers?.cookie
    return {
      headers: {
        ...headers,
        cookie: ssrCookie ?? headers?.cookie
      }
    }
  })
}

export default function createApolloClient(
  initialState: object,
  ctx: NextPageContext | null
) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.

  const fetchOptions = {
    credentials: 'include',
    redirect: 'manual'
  }

  const cookieLink = createCookieLink(ctx)

  const fetchWithCookies: typeof fetch = async (
    input: RequestInfo,
    init?: RequestInit
  ) => {
    let isomorphicFetch: typeof fetch
    if (typeof window === 'undefined') {
      isomorphicFetch = (await import('isomorphic-unfetch')).default
    } else {
      isomorphicFetch = window.fetch
    }
    const result = await isomorphicFetch(input, init)
    if (ctx?.res) {
      const cookiesFromApi = splitCookiesString(
        result.headers.get('set-cookie')!
      )
      if (cookiesFromApi.length > 0) {
        const parsedCookies = parseSetCookie(cookiesFromApi)
        const cookieStrings = Object.entries(parsedCookies).map(
          ([key, value]) => `${key}=${value}`
        )
        if (ctx.req) {
          const newCookieValues = [...cookieStrings]
          if (ctx.req.headers.cookie) {
            newCookieValues.unshift(ctx.req.headers.cookie)
          }
          ctx.req.headers.cookie = newCookieValues.join('; ')
        }
        if (ctx?.res) {
          ctx!.res!.setHeader('Set-Cookie', cookieStrings)
        }
      }
    }
    return result
  }

  const httpLink = new HttpLink({
    uri: `${apiUrl()}/graphql`,
    fetch: fetchWithCookies,
    fetchOptions
  })

  const persistedQueriesLink = createPersistedQueryLink({
    sha256
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: cookieLink.concat(persistedQueriesLink.concat(httpLink)),
    // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
    cache: new InMemoryCache({
      typePolicies: {
        RestaurantImage: {
          merge: true
        },
        HtmlRecord: {
          merge: true
        },
        TruckImage: {
          merge: true
        },
        SubscribedLotTruckFoodImage: {
          merge: true
        },
        ItemImage: {
          merge: true
        },
        LotFoodImage: {
          merge: true
        },
        LotImage: {
          merge: true
        },
        LotGroupImage: {
          merge: true
        },
        Money: {
          merge: true
        },
        CartPrice: {
          merge: true
        },
        PriceLine: {
          merge: true
        },
        Cart: {
          merge: true
        },
        Location: {
          merge: true
        },
        CartItem: {
          merge: true
        },
        CartItemPrice: {
          merge: true
        },
        RatingInfo: {
          merge: true
        },
        ImageAttachment: {
          merge: true
        },
        CateringMealPart: {
          keyFields: ['id', 'name', 'count']
        }
      }
    }).restore((initialState || {}) as NormalizedCacheObject)
  })
}
