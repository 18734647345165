import React, { useContext } from 'react'
import { Redirect } from 'next'
import Head from 'next/head'

export type RedirectContextValues = {
  redirect: (redirectAttrs: Redirect) => React.ReactElement
}

export const RedirectContext = React.createContext<RedirectContextValues>({
  // eslint-disable-next-line react/display-name
  redirect: (redirectAttrs: Redirect) => {
    return (
      <Head>
        <meta
          content={`0; url=${redirectAttrs.destination}`}
          httpEquiv="refresh"
        />
      </Head>
    )
  }
})

export const useRedirect = (): RedirectContextValues => {
  return useContext(RedirectContext)
}

type Props = {
  children: React.ReactElement
  onRedirect: (redirectAttrs: Redirect) => React.ReactElement
}

export const RedirectProvider = ({ onRedirect, children }: Props) => {
  return (
    <RedirectContext.Provider value={{ redirect: onRedirect }}>
      {children}
    </RedirectContext.Provider>
  )
}
