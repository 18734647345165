function isNonEmptyString(str: string) {
  return typeof str === 'string' && !!str.trim()
}

type CookieParams = {
  name: string
  value: string
  expires?: Date
  maxAge?: number
  secure?: boolean
  httpOnly?: boolean
  sameSite?: string
  domain?: string
  path?: string
  [key: string]: string | Date | number | boolean | undefined
}

const parseCookie = (setCookieValue: string) => {
  const parts = setCookieValue.split(';').filter(isNonEmptyString)

  const nameValuePairStr = parts.shift()!
  const parsed = parseNameValuePair(nameValuePairStr)
  const { name } = parsed
  const { value } = parsed

  const cookie: CookieParams = {
    name,
    value
  }

  parts.forEach((part: string) => {
    const sides = part.split('=')
    const key = sides.shift()!.trimLeft().toLowerCase()
    const v = sides.join('=')
    if (key === 'expires') {
      cookie.expires = new Date(v)
    } else if (key === 'max-age') {
      cookie.maxAge = parseInt(v, 10)
    } else if (key === 'secure') {
      cookie.secure = true
    } else if (key === 'httponly') {
      cookie.httpOnly = true
    } else if (key === 'samesite') {
      cookie.sameSite = v
    } else {
      cookie[key] = v
    }
  })

  return cookie
}

function parseNameValuePair(nameValuePairStr: string) {
  // Parses name-value-pair according to rfc6265bis draft

  let name = ''
  let value = ''
  const nameValueArr = nameValuePairStr.split('=')
  if (nameValueArr.length > 1) {
    name = nameValueArr.shift()!
    value = nameValueArr.join('=') // everything after the first =, joined by a "=" if there was more than one part
  } else {
    value = nameValuePairStr
  }

  return { name, value }
}

export const splitCookiesString = (cookiesString: string): string[] => {
  if (Array.isArray(cookiesString)) {
    return cookiesString
  }
  if (typeof cookiesString !== 'string') {
    return []
  }

  const cookiesStrings = []
  let pos = 0
  let start
  let ch
  let lastComma
  let nextStart
  let cookiesSeparatorFound

  function skipWhitespace() {
    while (pos < cookiesString.length && /\s/.test(cookiesString.charAt(pos))) {
      pos += 1
    }
    return pos < cookiesString.length
  }

  function notSpecialChar() {
    ch = cookiesString.charAt(pos)

    return ch !== '=' && ch !== ';' && ch !== ','
  }

  while (pos < cookiesString.length) {
    start = pos
    cookiesSeparatorFound = false

    while (skipWhitespace()) {
      ch = cookiesString.charAt(pos)
      if (ch === ',') {
        // ',' is a cookie separator if we have later first '=', not ';' or ','
        lastComma = pos
        pos += 1

        skipWhitespace()
        nextStart = pos

        while (pos < cookiesString.length && notSpecialChar()) {
          pos += 1
        }

        // currently special character
        if (pos < cookiesString.length && cookiesString.charAt(pos) === '=') {
          // we found cookies separator
          cookiesSeparatorFound = true
          // pos is inside the next cookie, so back up and return it.
          pos = nextStart
          cookiesStrings.push(cookiesString.substring(start, lastComma))
          start = pos
        } else {
          // in param ',' or param separator ';',
          // we continue from that comma
          pos = lastComma + 1
        }
      } else {
        pos += 1
      }
    }

    if (!cookiesSeparatorFound || pos >= cookiesString.length) {
      cookiesStrings.push(cookiesString.substring(start, cookiesString.length))
    }
  }

  return cookiesStrings
}

export default function parseSetCookie(lines: string[]) {
  const cookies: Record<string, string> = {}

  return lines.filter(isNonEmptyString).reduce((acc, str) => {
    const cookie = parseCookie(str)
    if (!cookie.domain) {
      cookie.domain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string
    }
    if (!cookie.path) {
      cookie.path = '/'
    }
    let cookieString = `${cookie.value}`
    if (cookie.expires) {
      cookieString += `; Expires=${cookie.expires.toUTCString()}`
    }
    if (cookie.maxAge) {
      cookieString += `; Max-Age=${cookie.maxAge}`
    }
    if (cookie.secure) {
      cookieString += '; Secure'
    }
    if (cookie.domain) {
      cookieString += `; domain=${cookie.domain}`
    }
    if (cookie.path) {
      cookieString += `; path=${cookie.path}`
    }
    if (cookie.httpOnly) {
      cookieString += '; HttpOnly'
    }
    if (cookie.sameSite) {
      cookieString += `; SameSite=${cookie.sameSite}`
    }
    // eslint-disable-next-line no-param-reassign
    acc[cookie.name] = cookieString
    return acc
  }, cookies)
}
